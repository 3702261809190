.greeting_container {
  --small-font-size: 18px;
  --large-font-size: 70px;
  --large-font-weight: bold;
  --button-color: #ffffff;
  --button-color-hover: #ffffff;
  --button-font-color: #606060;
  --large-margin: 15px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  max-width: 1500px;
  flex-wrap: wrap-reverse;
  margin: 5%;
}

.text_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 2%; */
}

.text {
  color: var(--secondary-color);
}

.small {
  font-size: var(--small-font-size);
  margin-top: 100px;
  margin-bottom: 0px;
}

.large {
  font-size: var(--large-font-size);
  font-weight: var(--large-font-weight);
  line-height: 95%;
}

.shine {
  color: var(--primary-color);
}

.underline {
  text-decoration: underline;
}

.margin_up_down {
  margin: var(--large-margin) 0 var(--large-margin) 0;
}

.button {
  font-family: 'Mada', sans-serif;
  width: 147px;
  height: 40px;
  cursor: pointer;
  padding: 5px 20px 5px 20px;
  border: none;
  border-radius: 10px;
  background-color: var(--button-color);
  color: var(--button-font-color);
  font-size: 1.25rem;
  margin-top: 10%;
  position: relative;
  overflow: hidden;
  margin-right: 1rem;
}

.resume_button {
  background-color: #ffffff;
  color: var(--button-font-color);
}

.resume_button:hover {
  box-shadow: var(--button-shadow-hover);
}

.button:hover {
  box-shadow: var(--button-shadow-hover);
  color: black;
}

.expand {
  content: "";
  position: absolute;
  left: 40%;
  bottom: 50%;
  border-radius: 1000000px;
  border: 1px solid var(--background-color);
  width: 1px;
  height: 1px;
  animation: ripple 600ms forwards;
}

/* .profile_pic {
  margin-right: 2%;
} */

@keyframes ripple {
  0% {
    transform: scale(0);
    opacity: .95;
  }
  100% {
    transform: scale(200);
    opacity: 0;
  }
}

@media (max-width: 680px) {
  .large {
    font-size: calc(var(--large-font-size) - 15px);
    font-weight: var(--large-font-weight);
    line-height: 95%;
  }
  
  .profile_pic {
    transform: scale(0.7);
  }
}