.icon-container {
  display: flex;
  justify-content: space-between;
}

.icon-container img:hover {
  box-shadow: 3px 3px 8px 0px var(--secondary-color);
  transition:0.1s;
}

.icon {
  margin: 0 .5rem;
  transform: scale(0.9);
  border-radius: 6px;
}
