*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
  outline-style: none;
}

body {
  --background-color: #ededed;
  --primary-color: #235ed3;
  --secondary-color: #2b2b2b;
  --topic-font-size: 2rem;
  --skill-font-color: #2b2b2b;
  --skill-font-size: 1.5rem;
  --content-max-width: 1500px;
  --content-min-height: 1100px;
  --tech-box-background-color: #ffffff;
  --button-shadow: 2px 2px 10px 1px #d3d3d380;
  --button-shadow-hover: 2px 2px 10px 1px #dbdbdb80;
  font-family: 'Mada', sans-serif;
}

.main {
  background-color: var(--background-color);
  min-height: 100vh;
  /* padding-bottom: 3rem; */
}

.landing-page {
  /* min-height: var(--content-min-height); */
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.projects-page {
  padding: 3rem 0;
  min-height: 750px;
  position: relative;
}

.skills-page {
  padding: 4rem 0;
}
