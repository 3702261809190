.logo-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  padding: 1rem 2rem;
  background-color: var(--background-color);
  z-index: 10;
  display: flex;
  align-items: center;
}

.logo {
  cursor: pointer;
}

.logo:hover {
  box-shadow: 3px 3px 8px 0px var(--secondary-color);
  transition: 0.2s;
}